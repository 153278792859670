(function ($) {
  'use strict';

  // Plugin default options
  var defaultOptions = {
  };

  // If the plugin is a button
  function btnDef(trumbowyg) {
    return {
      ico: 'viewHtml', // irrelevant
      fn: function () {
        var html_content = trumbowyg.html();
        trumbowyg.html(
          html_content
          .replace(/<a[^<>]*class="placeholder-token"[^<>]*>((?:<b>|<i>|<strike>)*)([A-Z_][\w\-]*)\s*(?:&nbsp;)?((?:<\/b>|<\/i>|<\/strike>)*)\<\/a>/gi, '$1{$2}$3')
          .replace(/\{([A-Z_][\w\-]*)\}(?![^\<]*\>)/gi, '<a href="#" class="placeholder-token">$1&nbsp;</a>')
        );

        $.ajax({
          type: 'get',
          url: '/admin/utilities/placeholders',
          dataType: 'json',
          data: trumbowyg.o.extra,
          success: function(data) {
            var array_of_valid_names = $.map(data, function(item, i) { return item.name; });

            $.each($('.trumbowyg-editor .placeholder-token'), function(i, item) {
              if (array_of_valid_names.includes(item.innerHTML.replace('&nbsp;', '').toUpperCase())) {
                $(this).css('backgroundColor', '#a0ec44');
              }
            });
          }
        });
      }
    }
  }

  $.extend(true, $.trumbowyg, {
    // Add some translations
    langs: {
      en: {
        verifyPlaceholders: 'Check placeholders'
      }
    },
    // Register plugin in Trumbowyg
    plugins: {
      verifyPlaceholders: {
        // Code called by Trumbowyg core to register the plugin
        init: function(trumbowyg) {
          // Fill current Trumbowyg instance with the plugin default options
          trumbowyg.o.plugins.verifyPlaceholders = $.extend(true, {}, defaultOptions, trumbowyg.o.plugins.verifyPlaceholders || {});

          // If the plugin is a button
          trumbowyg.addBtnDef('verifyPlaceholders', btnDef(trumbowyg));
        },
        // Return a list of button names which are active on current element
        tagHandler: function(element, trumbowyg) {
          return [];
        },
        destroy: function(trumbowyg) {
        }
      }
    }
  })
})(jQuery);
