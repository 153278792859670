(function ($) {
  'use strict';

  $.extend(true, $.trumbowyg, {
    langs: {
      en: {
        insertPlaceholder: 'Insert placeholder'
      }
    },
    plugins: {
      insertPlaceholder: {
        init: function (trumbowyg) {
          var btnDef = {
            fn: function () {
              var title_html = '<div class="trumbowyg-modal-title">Insert Placeholder</div>';
              var controls_html =
                '<form action>' +
                  '<div class="grid-x">' +
                    '<div class="cell">' +
                      '<label style="height: auto; overflow: visible;">' +
                        '<select id="insert-placeholder-box" name="placeholder"><option value="">Select a placeholder</option></select>' +
                      '</label>' +
                      '<div class="buttons">' +
                        '<div class="form-row">' +
                          '<div class="controls">' +
                            '<button class="trumbowyg-modal-button trumbowyg-modal-submit" type="submit">Confirm</button>' +
                            '<button class="trumbowyg-modal-button trumbowyg-modal-reset" type="reset">Cancel</button>' +
                          '</div>' +
                        '</div>' +
                      '</div>' +
                    '</div>' +
                  '</div>' +
                '</form>';

              trumbowyg.openModal(null, title_html + controls_html, false);

              $('.trumbowyg-modal-submit').on('click', function(e) {
                var html;

                var placeholder = $('#insert-placeholder-box').val();

                if (placeholder.includes("_URL") || placeholder.includes("_LINK") || $('#insert-placeholder-box').text().includes("URL")) {
                  html = `<a href="{${placeholder}}">Click here</a>`;
                } else {
                  html = `<a href="#" class="placeholder-token">${placeholder}&nbsp;</a>`;
                }

                trumbowyg.restoreRange();
                trumbowyg.execCmd('insertHTML', html, false, true);
                $(this).off();
                trumbowyg.closeModal();

                e.preventDefault();
              });

              $('.trumbowyg-modal-reset').on('click', function() {
                trumbowyg.closeModal();
              });

              function initialize_placeholder_dropdown(selector) {
                return $(selector).selectize({
                  preload: true,
                  valueField: 'name',
                  labelField: 'name_and_title',
                  searchField: ['name', 'title', 'description'],
                  options: [],
                  create: true,
                  render: {
                    option: function(item, escape) {
                      var output = '<div>' +
                        '<span class="title">' +
                          '<span class="name">' + escape(item.name) + '</span>' +
                          '<span class="title">' + escape(item.title) + '</span>' +
                        '</span>';

                      if (item.description != null) {
                        output += '<span class="description">' + escape(item.description) + '</span>';
                      }
                      output += '</div>';

                      return output;
                    }
                  },
                  load: function(query, callback) {
                    $.ajax({
                      type: 'GET',
                      url: '/admin/utilities/placeholders',
                      dataType: 'json',
                      data: trumbowyg.o.extra,
                      error: function() {
                        callback();
                      },
                      success: function(data) {
                        callback(data);
                      }
                    });
                  }
                });
              }

              initialize_placeholder_dropdown('#insert-placeholder-box');
            }
          };

          trumbowyg.addBtnDef('insertPlaceholder', btnDef);
        }
      }
    }
  });
})(jQuery);
