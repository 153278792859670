// For some reason, you need both the ProvidePlugin in environment.js as well as window here.

// Exports all happen at the end of the file, apparently
import './jquery-global.js';

import Cookies from "../js_cookie/js.cookie.min";
window.Cookies = Cookies;

import "../regex_selector/jquery.regex-selector";

import Foundation from "foundation-sites";
window.Foundation = Foundation;

import moment from "moment";
window.moment = moment;
import "moment-timezone";

import Chart from "chart.js";
window.Chart = Chart;

// import Vue from 'vue';
import Vue from 'vue/dist/vue.esm.js';
window.Vue = Vue;

import Colcade from 'colcade';
window.Colcade = Colcade;

import Rails from "@rails/ujs";
Rails.start();

import Clipboard from 'clipboard';
window.ClipboardJS = Clipboard;

// These should automatically include jquery-ui
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/droppable";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui/ui/effects/effect-highlight";

import "jquery-autosize";
import "jquery-datetimepicker";
import "jquery-datetimepicker/jquery.datetimepicker.css";

import "spectrum-colorpicker";
import "jquery-serializejson";
import "jquery_metadata";

import hopscotch from "hopscotch";
window.hopscotch = hopscotch;
import "hopscotch/dist/css/hopscotch.css";

import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

// Unfortunately, we can't put these in admin.js
// or else $.fn.dataTable will be undefined.
// Not sure why...
import 'datatables.net';
import 'datatables.net-dt';
// import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-zf';
import 'datatables.net-buttons';
import 'datatables.net-buttons-zf';
import 'datatables.net-plugins/sorting/datetime-moment';
import 'jquery-datatables-checkboxes';
import 'datatables.net-buttons/js/buttons.html5.js';

// Duet

// Import Duet Date Picker
import { defineCustomElements } from "@duetds/date-picker/dist/loader";
import '@duetds/date-picker/dist/duet/themes/default.css';

// Register Duet Date Picker
defineCustomElements(window);

// import trumbowyg_icons from 'trumbowyg/dist/ui/icons.svg';
import 'trumbowyg';
import 'trumbowyg/dist/plugins/mention/trumbowyg.mention.js';
window.$.trumbowyg.svgPath = false;
window.$.trumbowyg.hideButtonTexts = true;

import { replace_placeholder_token } from "../xinspire/admin/placeholder_highlighting";
window.replace_placeholder_token = replace_placeholder_token;

import "../xinspire/admin/trumbowyg_view_html2";
import "../xinspire/admin/trumbowyg_quick_placeholder";
import "../xinspire/admin/trumbowyg_insert_placeholder";
import "../xinspire/admin/trumbowyg_verify_placeholders";
import "../xinspire/admin/trumbowyg_create_button";

import { rerank_table, update_function } from "../xinspire/sort";
import { update_index, update_index_post } from "../xinspire/filter";
import TruncateElement from '../xinspire/truncate_element';
import "../xinspire/thumbnails";
import "../xinspire/admin/admin_form_params";

// This is necessary so that these functions go into the global namespace
window.rerank_table = rerank_table;
window.update_function = update_function;
window.update_index = update_index;
window.update_index_post = update_index_post;
window.TruncateElement = TruncateElement;

// 2020-02-24 This is needed because the php-date-formatter 1.3.4
// from npm, used by jquery-datetimepicker 2.5.21, is buggy
// 2021-04-19 Still buggy
window.$.datetimepicker.setDateFormatter({
  parseDate: function(date, format) {
    var d = window.moment(date, format);
    return d.isValid() ? d.toDate() : false;
  },

  formatDate: function(date, format) {
    var d = window.moment(date).format(format);
    return d;
  }
});


import { connect, createLocalVideoTrack, createLocalAudioTrack } from "twilio-video";
window.twilio_connect = connect;
window.twilio_createLocalVideoTrack = createLocalVideoTrack;
window.twilio_createLocalAudioTrack = createLocalAudioTrack;
