jQuery(function() {
  // We use jQuery to store the origin-item-id when a link is clicked

  $(document).on('click', 'a', function() {
    $.last_origin_item_id = $(this).data('origin-item-id');
    $.parent_path = $(this).data('parent-path');
		$.public_program_id = $(this).data('public-program-id');
  });

  // This is needed to track where things are coming from
  $(document).on('open.zf.reveal', '[data-reveal]', function() {
    $(this).find('form').append(
      $("<input>").attr({
        name: "origin_item_id",
        type: "hidden",
        value: $.last_origin_item_id
      })
    ).append(
      $("<input>").attr({
        name: "parent_path",
        type: "hidden",
        value: $.parent_path
      })
    ).append(
      $("<input>").attr({
        name: "public_program_id",
        type: "hidden",
        value: $.public_program_id
      })
    );
  });
});
