(function ($) {
  'use strict';

  $.extend(true, $.trumbowyg, {
    langs: {
      en: {
        createButton: 'Create button',
        openInNewTab: 'Open in new tab'
      }
    },
    plugins: {
      createButton: {
        init: function (t) {
          var btnDef = {
            ico: 'base64',
            fn: function () {
              var documentSelection = t.doc.getSelection(),
                  selectedRange = documentSelection.getRangeAt(0),
                  node = documentSelection.focusNode,
                  text = new XMLSerializer().serializeToString(selectedRange.cloneContents()) || selectedRange + '',
                  url,
                  title,
                  target;

              while (['A', 'DIV'].indexOf(node.nodeName) < 0) {
                node = node.parentNode;
              }

              if (node && node.nodeName === 'A') {
                var $a = $(node);
                text = $a.text();
                url = $a.attr('href');
                if (!t.o.minimalLinks) {
                    title = $a.attr('title');
                    target = $a.attr('target');
                }
                var range = t.doc.createRange();
                range.selectNode(node);
                documentSelection.removeAllRanges();
                documentSelection.addRange(range);
              }

              t.saveRange();

              // Copied from trumbowyg core code for createLink
              var options = {
                url: {
                  label: t.lang.linkUrl || 'URL',
                  required: true,
                  value: url
                },
                text: {
                  label: t.lang.text,
                  value: text
                }
              };
              if (!t.o.minimalLinks) {
                $.extend(options, {
                  title: {
                    label: t.lang.title,
                    value: title
                  },
                  target: {
                    label: t.lang.openInNewTab,
                    type: 'checkbox',
                    value: target
                  }
                });
              }

              t.openModalInsert(t.lang.createButton, options, function (v) { // v is value
                var url = t.prependUrlPrefix(v.url);
                if (!url.length) {
                    return false;
                }

                var link = $(['<a href="', url, '" class="button">', v.text || v.url, '</a>'].join(''));

                if (v.title) {
                    link.attr('title', v.title);
                }

                if (v.target) {
                    link.attr('target', '_blank');
                }
                t.range.deleteContents();
                t.range.insertNode(link[0]);
                t.syncCode();
                t.$c.trigger('tbwchange');
                return true;
              });
            }
          };

          t.addBtnDef('createButton', btnDef);
        }
      }
    }
  });
})(jQuery);
