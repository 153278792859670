(function ($) {
  'use strict';

  // Plugin default options
  var defaultOptions = {
  };

  // If the plugin is a button
  function btnDef(trumbowyg) {
    return {
      ico: 'viewHtml',
      class: 'trumbowyg-not-disable',
      fn: function () {
        var t = trumbowyg,
            prefix = t.o.prefix;

        if (t.o.autogrowOnEnter) {
            t.autogrowOnEnterDontClose = !t.$box.hasClass(prefix + 'editor-hidden');
        }

        t.semanticCode(false, true);

        setTimeout(function () {
          t.doc.activeElement.blur();
          t.$box.toggleClass(prefix + 'editor-hidden ' + prefix + 'editor-visible');
          t.$btnPane.toggleClass(prefix + 'disable');
          $('.' + prefix + 'viewHTML2-button', t.$btnPane).toggleClass(prefix + 'active');
          if (t.$box.hasClass(prefix + 'editor-visible')) {
              t.$ta.attr('tabindex', -1);
          } else {
              t.$ta.removeAttr('tabindex');
          }

          if (t.o.autogrowOnEnter && !t.autogrowOnEnterDontClose) {
              t.autogrowEditorOnEnter();
          }

          t.$c.trigger('tbwtoggle');
        }, 0);
      }
    }
  }

  $.extend(true, $.trumbowyg, {
    // Add some translations
    langs: {
      en: {
        viewHTML2: 'View HTML'
      }
    },
    // Register plugin in Trumbowyg
    plugins: {
      viewHTML2: {
        // Code called by Trumbowyg core to register the plugin
        init: function(trumbowyg) {
          // Fill current Trumbowyg instance with the plugin default options
          trumbowyg.o.plugins.viewHTML2 = $.extend(true, {}, defaultOptions, trumbowyg.o.plugins.viewHTML2 || {});

          // If the plugin is a button
          trumbowyg.addBtnDef('viewHTML2', btnDef(trumbowyg));
        },
        // Return a list of button names which are active on current element
        tagHandler: function(element, trumbowyg) {
          return [];
        },
        destroy: function(trumbowyg) {
        }
      }
    }
  })
})(jQuery);
