$(function() {
  $(".thumbnail-panel").click(function() {
    var detailed_description = $('#detailed-description');
    var current_panel = $(this);
    var content = detailed_description.children('section');

    if (detailed_description.is(":visible")) {
      if (content.html() != current_panel.find('.description').html()) {
        content.fadeOut((detailed_description.is(":visible")) ? 300 : 0, function() {
          content.html(current_panel.find('.description').html()).fadeIn(300);
        });
      }
    } else {
      // Populate the data first and then slidedown
      content.html(current_panel.find('.description').html());
      detailed_description.slideDown();
    }
  });

  $("a.close").click(function() {
    $('#detailed-description').slideUp();
  });
});