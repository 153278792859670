export function rerank_table() {
  $('.items-container .ranked-section').each(function() {
    $(this).find('tr:not(.almost-transparent) .rank, .grid-cell:not(.almost-transparent) .rank').each(function(i) {
      $(this).html(i + 1);
    });
  });

  // Aug 2023: For the new user application ranking ui we will add the # sign
  $('.application-page .ranked-section').each(function() {
    $(this).find('.grid-cell:not(.almost-transparent) .rank').each(function(i) {
      $(this).html('#' + (i + 1));
    });
  });
}

export function update_function(e, ui, action_url_elem) {
  var item_id = ui.item.data('target-id'); // ui.item is an element from items; should usually be the tr
  var program_id = ui.item.data('target-program-id');
  var position = ui.item.index();

  if ($.isNumeric(program_id)) {
    position = ui.item.index('[data-target-program-id="' + program_id + '"]');
  }

  var container = ui.item.parents(action_url_elem).first();

  $.ajax({
    type: 'post',
    url: container.data('action-url'),
    dataType: 'script',
    // XXX_position => XXX is the column name expected in ranked-model
    data: { id: item_id, items: { position_position: position } }
  });
}

$(function() {
  $("#form-tabs.sortable").sortable({
    axis: "x",
    items: 'li',
    distance: 5,
    stop: function() {
      // $tabs.tabs( "refresh" );
    },
    update: function(e, ui) {
      update_function(e, ui, '.tabs');
    }
  });

  $('.row.sortable').sortable({
    axis: 'y',
    items: '.field-row',
    distance: 5,
    handle: '.drag-handle',
    update: function(e, ui) {
      update_function(e, ui, '.row');
    }
  });

  $('.grid-x.sortable').sortable({
    axis: 'y',
    items: '.field-row',
    distance: 5,
    handle: '.drag-handle',
    update: function(e, ui) {
      update_function(e, ui, '.grid-x');
    }
  });

  $('table.sortable').sortable({
    axis: 'y',
    items: 'tr',
    distance: 5,
    handle: '.drag-handle',
    update: function(e, ui) {
      update_function(e, ui, 'table');
    }
  });

  $('.tabs-panel.sortable, #no-tab.sortable').sortable({
    // axis: 'y',
    items: '> .tab-content > div.unit-container',
    handle: '.action.drag-handle',
    distance: 5,
    helper: function(e, div_row) {
      var $originals = div_row.children(); // a collection of div columns
      var $helper = div_row.clone(); // also a collection of div columns
      $helper.children().each(function(index) {
        // Set td sizes to match the original td sizes
        $(this).width($originals.eq(index).width());
      });
      return $helper;
    },
    stop: function(e, ui) {
      // highlight the row on drop to indicate an update
      ui.item.children('td').effect('highlight', {color: '#eee'}, 1000);
    },
    update: function(e, ui) {
      update_function(e, ui, '.sortable');
    }
  });

  // For move-* functions
  $('table').on('click', '.move-up', function () {
    var clicked_tr = $(this).closest('tr');
    clicked_tr.insertBefore(clicked_tr.prev());
  });

  $('table').on('click', '.move-down', function () {
    var clicked_tr = $(this).closest('tr');
    clicked_tr.insertAfter(clicked_tr.next());
  });
});
