export function replace_placeholder_token(obj) {
  var old_text = jQuery(obj).html();
  var new_text = old_text.replace(/\{([A-Z_][\w\-]*)\}(?![^\<]*\>)/gi, '<a href="#" class="placeholder-token">$1&nbsp;</a>');
  jQuery(obj).html(new_text);
}

jQuery(function() {
  // Add other static selectors here
  $('.items-table:not(.literal) td').each(function() { replace_placeholder_token($(this)) });
});
