/* ===========================================================
 * trumbowyg.quickPlaceholder.js v0.1x
 * Mention plugin for Trumbowyg
 * http://alex-d.github.com/Trumbowyg
 * ===========================================================
 * Author : Viper
 *      Github: https://github.com/Globulopolis
 *      Website: http://киноархив.com
 * Modified by Xuan Wu - 2020-03-16
 */

(function ($) {
  'use strict';

  var defaultOptions = {
    source: [],
    formatDropdownItem: formatDropdownItem,
    formatResult: formatResult
  };

  $.extend(true, $.trumbowyg, {
    langs: {
      // jshint camelcase:false
      en: {
        quickPlaceholder: 'Quick placeholder'
      }
      // jshint camelcase:true
    },

    plugins: {
      quickPlaceholder: {
        init: function (trumbowyg) {
          trumbowyg.o.plugins.quickPlaceholder = $.extend(true, {}, defaultOptions, trumbowyg.o.plugins.quickPlaceholder || {});

          var btnDef = {
            dropdown: buildDropdown(trumbowyg.o.plugins.quickPlaceholder.source, trumbowyg)
          };

          trumbowyg.addBtnDef('quickPlaceholder', btnDef);
        }
      }
    }
  });

  /**
   * Build dropdown list
   *
   * @param {Array}   items    Items
   * @param {object}  trumbowyg  Editor
   *
   * @return {Array}
   */
  function buildDropdown(items, trumbowyg) {
    var dropdown = [];

    $.each(items, function (i, item) {
      var btn = 'quickPlaceholder-' + i,
        btnDef = {
          hasIcon: false,
          text: trumbowyg.o.plugins.quickPlaceholder.formatDropdownItem(item),
          fn: function () {
            trumbowyg.execCmd('insertHTML', trumbowyg.o.plugins.quickPlaceholder.formatResult(item));

            return true;
          }
        };

      trumbowyg.addBtnDef(btn, btnDef);
      dropdown.push(btn);
    });

    return dropdown;
  }

  function formatDropdownItem(item) {
    return item.login;
  }

  // This function is a dummy because it gets overridden when we load rich_text.js
  function formatResult(item) {
    return '@' + item.login + ' ';
  }
})(jQuery);
